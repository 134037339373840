import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  ViewContainerRef,
  TemplateRef,
} from '@angular/core';
import anime from 'animejs';
import { filter } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-maneuver-navbar',
  templateUrl: './maneuver-navbar.component.html',
  styleUrls: ['./maneuver-navbar.component.scss'],
})
export class ManeuverNavbarComponent implements OnInit {
  expand: boolean = false;
  top;
  left;
  menuTop;
  menuLeft;
  isClicked: boolean = true;
  mouseOutOfBounds: boolean = false;
  pageName: string;
  fileUrl = '/assets/RINCY_P_RES.pdf';
  @HostListener('document:click', ['$event'])
  onClick($event) {
    this.initiateNav();
  }

  @HostListener('document:mousemove', ['$event'])
  onMousemove($event) {
    this.top = $event.pageY;
    this.left = $event.pageX;
    this.menuTop = this.isClicked ? this.menuTop : this.top;
    this.menuLeft = this.isClicked ? this.menuLeft : this.left;
  }

  //3
  @ViewChild('vc', { read: ViewContainerRef }) vc: ViewContainerRef;

  @ViewChild('gridtpl', { read: TemplateRef }) gridtpl: TemplateRef<any>;
  prevPage: string = 'home';
  currPage: string = 'home';

  goToPage(pageName) {
    if (!pageName) return;
    this.prevPage = this.currPage;
    this.currPage = pageName;
    let view = this.gridtpl.createEmbeddedView(null);
    this.vc.insert(view);
    this.router.navigate(['/' + pageName]);
  }
  //3
  onGridFellOver(ev) {
    if (ev) {
      this.vc.clear();
    }
  }
  initiateNav() {
    if (!this.isClicked) {
      this.isClicked = true;
      this.menuTop = this.top;
      this.menuLeft = this.left;
      anime({
        targets: '.menu',
        width: '25em',
        background: 'rgb(10, 25, 47)',
        borderRadius: '0%',
        borderTop: '1px solid #D8D8D8',
        easing: 'easeInOutQuad',
        duration: 400,
        // translateX : -150
      });
      anime({
        targets: '.menuTitle',
        translateX: 0,
        translateY: -70,
        color: '#fff',
      });
    } else {
      this.isClicked = false;
      anime({
        targets: '.menu',
        width: '5em',
        background: '#fff',
        borderRadius: '100%',
        borderTop: '1px solid #D8D8D8',
        easing: 'easeInOutQuad',
        duration: 400,
      });
      anime({
        targets: '.menuTitle',
        translateX: 0,
        translateY: 0,
        color: 'rgb(10, 25, 47)',
      });
    }
  }

  constructor(private shared: SharedService, private router: Router) {
    this.currPage = 'home';
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        var trimmedPath = event['url'];
        trimmedPath = trimmedPath.match(/home|testimonials|timeline/g);
        this.currPage = trimmedPath ? trimmedPath[0] : 'home';
      });
  }

  ngOnInit(): void {
    this.initiateNav();
  }
}
