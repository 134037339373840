<!-- 1 -->
<app-maneuver-navbar></app-maneuver-navbar> 



<div class="app-container container" >
   
    <router-outlet ></router-outlet>
</div>

