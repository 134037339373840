import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
// import { GetResponsiveScreenDimensionsService } from '../../services/get-responsive-screen-dimensions.service';
import anime from 'animejs';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  query,
  stagger,

} from '@angular/animations';
import { SharedService } from 'src/app/shared.service';
// declare let anime : any;

const MathUtils = {
  lineEq: (y2, y1, x2, x1, currentVal) => {
    // y = mx + b 
    var m = (y2 - y1) / (x2 - x1), b = y1 - m * x1;
    return m * currentVal + b;
  },
  lerp: (a, b, n) => (1 - n) * a + n * b
};
@Component({
  selector: 'app-grids',
  templateUrl: './grids.component.html',
  styleUrls: ['./grids.component.scss'],
})


export class GridsComponent implements OnInit {
  //4
  @Input() pageName: string;
  @Input() ifNumb: string;

  @Output() gridsFellOverEvent = new EventEmitter<boolean>();
  gridWidth: number;
  gridHeight: number;
  gridItems;
  DOM;
  pos;
  // posY;
  // posX;
  animation;
  isAnimating;

  mode: string;
  // itemsTotal;
  // height;
  // extraHeight;
  // columns;
  // gridDef;
  // gridTranslation;
  // lerpFactor;
  // resizeTimer;
  @Output() darkModeToggleAction = new EventEmitter<string>();
  constructor(private shard: SharedService) {
    this.isAnimating = true;
    this.gridItems = Array.from({ length: 64 }, () => Math.floor(Math.random() * 1));

    // this.getScreenDim.screenArea$.subscribe((o) => {
    //   console.log(o);
    //   this.gridHeight = (o.height) / 8;
    //   this.gridWidth = (o.width) / 8;
    // });

    // this.navCtrl.animInitiated$.subscribe(
    //   pageName => {
    //     this.pageName = pageName;
    //     // if (pageName == 'skills') {
    //       // this.selectedGrid(0);

    //     // }
    //   }
    // )
  }
  selectedGrid(index, page) {
    this.pos = index;
    var pageid = '.' + page + ' .grid-item';
    this.animation = anime({
      targets: pageid,
      opacity: [
        {
          value: 1, duration: 500
        },
        {
          value: 0,
          duration: 500,
          easing: 'easeInQuad'
        }
      ],
      translateY: [
        {
          value: 0, duration: 500
        },
        {
          value: () => anime.random(100, 400),
          duration: 500,
          easing: 'easeInQuad'
        }
      ],
      rotate: [
        {
          value: target => target.dataset.rotateDir === 'l' ? anime.random(2, 15) : anime.random(-0, -15),
          duration: 500,
          easing: 'easeInOutSine'
        },
        {
          value: target => target.dataset.rotateDir === 'l' ? 8 : -8,
          duration: 500,
          easing: 'easeInQuad'
        }
      ],
      delay: anime.stagger(350, { grid: [8, 8], from: this.pos })
    });
    this.animation.play();
    this.animation.finished.then(() => {
      this.isAnimating = false;
      this.gridsFellOverEvent.emit(true);
    });
  }
  ngAfterViewInit() {
    this.DOM = { el: document.querySelector('.grid-wrap') };

    this.DOM.items = [...this.DOM.el.children];
    this.DOM.items.forEach(item => {
      const rand = Math.random() < 0.5;
      item.style.transformOrigin = rand ? '0% 0%' : '100% 0%';
      item.dataset.rotateDir = rand ? 'l' : 'r';
    })
    //6
    if (this.ifNumb && this.ifNumb == 'notnumb')
      this.selectedGrid(0, this.pageName);

  }


  ngOnInit(): void {

  }

}







