<div
  class="grid-wrap" 
  [ngClass]="pageName"
  [class.dark]="mode == 'dark'"
  [class.light]="mode == 'light'"
>

  <div
    class="grid-item"
    *ngFor="let item of gridItems; let i = index"
  >
    
    <span class="bits"></span>
  </div>
</div>
