import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {


  private navSource = new Subject<string>();

  navActionStarted$ = this.navSource.asObservable();

  startNavAction(pageName: string) {
    this.navSource.next(pageName);
  }

  
  constructor() { }
}
