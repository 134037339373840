<!-- <div class="dot" *ngIf = "isClicked" [style.transform] = "'translate('+left+'px,'+top+'px)'" >

</div> -->
<div
  class="menuContainer"
  [style.transform]="'translate(' + menuLeft + 'px,' + menuTop + 'px)'"
>
  <span class="menuTitle">menu</span>
  <!-- 
[style.top.px]="menuTop"
[style.left.px]="menuLeft"
-->

  <div class="menu" [style.z-index]="isClicked ? '1009' : '1011'">
    <ul [hidden]="!isClicked">
      <li *ngIf="currPage != 'home'" (click)="goToPage('home')">home</li>
      <li *ngIf="currPage != 'timeline'" (click)="goToPage('timeline')">
        timeline
      </li>
      <li *ngIf="currPage != 'testimonials'" (click)="goToPage('testimonials')">
        testimonials
      </li>
      <li>
        <a
          href="https://www.linkedin.com/in/rincy-philip-54631941"
          target="_blank"
          rel="noopener"
        >
          <img
            class="linkedin-img"
            src="/assets/linkedinicon.png"
            alt="linkedin"
          />
        </a>
      </li>
      <li>
        <a download [href]="fileUrl">
          RESUME
        </a>
      </li>
    </ul>
  </div>
</div>

<ng-container #vc></ng-container>

<ng-template #gridtpl>
  <app-grids
    id="grid-modal"
    class="notnumb"
    [ifNumb]="'notnumb'"
    [pageName]="prevPage"
    (gridsFellOverEvent)="onGridFellOver($event)"
  ></app-grids>
</ng-template>
